//
// Bootstrap-custom.scss
//

/*********************************/
/*         Bootstrap-custom      */
/*===============================*/

.container {
    @media (min-width: 1400px) {
        max-width: 1140px !important;
    }
}

.row > * {
    position: relative;
}

@each $heading,
$size in $heading-font-sizes {
    #{$heading}, .#{$heading} {
        font-size: $size !important;
    }
}

@each $heading,
$size in $display-font-sizes {
    .#{$heading} {
        font-size: $size !important;
    }
}

@each $name,
$value in $colors {
    .bg-#{$name} {
        background-color: #{$value} !important;
    }
    .bg-soft-#{$name} {
        background-color: rgba($value, 0.05) !important;
        color: #{$value} !important;
    }
    .bg-gradient-#{$name} {
        /*background: linear-gradient(to right, $value 0%, $gradient 100%);*/
        background: $bg-gradient-primary;
    }
    .text-#{$name} {
        color: #{$value} !important;
    }
    .text-gradient-#{$name} {
        background: linear-gradient(to right, $value 0%, $gradient 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    a {
        &.text-#{$name} {
            &:hover,
            &:focus {
                color: darken($value, 5%) !important;
            }
        }
    }
}

.d-flex {
    .flex-1 {
        flex: 1;
    }
}

.bg-black {
    background: $black !important;
}

//Video tag
video {
    &:focus {
        outline: none;
    }
}

//li (list inline item)
.list-inline-item:not(:last-child) {
    margin-right: 0px;
    margin-bottom: 5px;
}

// Rounded
.rounded {
    border-radius: 6px !important;
}
.rounded-top {
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
}
.rounded-left {
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
}
.rounded-bottom {
    border-bottom-left-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
}
.rounded-right {
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
}

.rounded-md {
    border-radius: 10px !important;
}
.rounded-lg {
    border-radius: 30px !important;
}

//Border
.border {
    border: 1px solid $gray-200 !important;
}
.border-top {
    border-top: 1px solid $gray-200 !important;    
}
.border-bottom {
    border-bottom: 1px solid $gray-200 !important;    
}
.border-left {
    border-left: 1px solid $gray-200 !important;    
}
.border-right {
    border-right: 1px solid $gray-200 !important;    
}

.img-thumbnail {
    padding: 2px;
}

//Small
.small, small {
    font-size: 90%;
}

//card
.card {
    background: transparent;
    border: 0;
    .card-body {
        padding: 1.5rem;
    }
    .card-img {
        position: relative;
        .card-overlay {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: 0;
            background: $card-overlay;
            transition: all 0.5s ease;
        }
    }
    &:hover {
        .card-img {
            .card-overlay {
                opacity: 1;
            }
        }
    }
}

.fw-semibold {
    font-weight: 500 !important;
}

.fw-bold {
    font-weight: 700 !important;
}


// Opacity
.opacity-05 {
    opacity: 0.05;
}

.opacity-075 {
    opacity: 0.075;
}

.opacity-1 {
    opacity: 0.1;
}

.opacity-2 {
    opacity: 0.2;
}

.opacity-3 {
    opacity: 0.3;
}

.opacity-4 {
    opacity: 0.4;
}

.opacity-5 {
    opacity: 0.5;
}

.opacity-6 {
    opacity: 0.6;
}

.opacity-7 {
    opacity: 0.7;
}

.opacity-8 {
    opacity: 0.8;
}

.opacity-9 {
    opacity: 0.9;
}
.opacity-10 {
    opacity: 1 !important;
}

@media (max-width: 768px) {
    .display-1 {
        font-size: 56px !important;
    }
    .display-2 {
        font-size: 50px !important;
    }
    .display-3 {
        font-size: 42px !important;
    }
    .display-4 {
        font-size: 36px !important;
    }
    .display-5 {
        font-size: 34px !important;
    }
    .display-6 {
        font-size: 32px !important;
    }
}

//Margin Left Css
.ms-n3 {
    margin-left: -16px;
}

.mt-n3 {
    margin-top: -16px;
}
.mt-n4 {
    margin-top: -24px;
}
.mt-n5 {
    margin-top: -48px;
}
.mt-n80 {
    margin-top: -80px;
}

//Z Index css
.z-index-1 {
    z-index: 1;
}
.z-index-0 {
    z-index: 0;
}
.z-index-m-1 {
    z-index: -1;
}

//Modal
.modal {
    .modal-dialog {
        .modal-content {
            .modal-header {
                button {
                    &.btn-close {
                        background: none;
                    }
                }
            }
            button {
                &.btn-close {
                    background: none;
                }
            }
        }
        &.modal-sm {
            max-width: 450px;
        }
    }
}