//
// _blog.scss
//

/*********************************/
/*         Blog                  */
/*===============================*/
@each $name,
$value in $colors {
    .blog {
        &.blog-#{$name} {
            .tag {
                background: $value !important;

                &.gradient {
                    background: linear-gradient(to right, $value 0%, $gradient 100%) !important;
                }
            }
            .title {
                &:hover {
                    color: $value !important;
                }
            }
            .link {
                color: $value !important;
            }

            .btn-link {
                padding: 0 !important;
                color: $dark;
                position: relative;
                &:hover, &:focus, &:active, &.active, &.focus, &:not(:disabled):not(.disabled):active{
                    color: $value !important;
                }
                &:after {
                    background-color: $value !important;
                }
            }
        }
    }
}


.blog {
    transition: all 0.5s ease;
    .tag-container {
        position: absolute;
        top: -13px;
    }
    .tag {
        position: relative;
        &:hover {
            color: $white !important;
        }
    }
    .like-icon {
        width: 32px;
        height: 32px;
        background-color: $white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon {
            -webkit-text-stroke: 1px $dark;
            -webkit-text-fill-color: transparent;
            &:hover, &:focus {
                -webkit-text-stroke: $danger;
                -webkit-text-fill-color: $danger;
            }
        }
    }
}



//Sidebar
.sidebar {
    .widget {
        .title {
            &:hover {
                color: $primary !important;
            }
        }
        .widget-search {
            form {
                position: relative;
            }
        }
        .widget-search, .searchform { 
            input[type="text"] {
                box-shadow: none;
                padding: 12px 15px;
                height: 45px;
                font-size: 14px;
                display: block;
                width: 100%;
                outline: none !important;
                padding-right: 45px;
            }
            input[type="submit"] {
                position: absolute;
                top: 5px;
                right: 10px;
                opacity: 0;
                width: 40px;
                height: 40px;
            }
        }
        .widget-search {
            .searchform:after {
                content: "\F0349";
                position: absolute;
                font-family: "Material Design Icons";
                right: 16px;
                top: 15px;
                font-size: 20px;
                line-height: 20px;
                pointer-events: none;
            }
        }
        .widget-title {
            font-weight: 500;
        }
    }
}


.tagcloud {
    > a {
        background: $gray-100;
        color: $dark;
        display: inline-block;
        font-size: 12px;
        letter-spacing: 1px;
        margin: 5px 10px 5px 0;
        padding: 8px 12px 7px;
        text-transform: uppercase;
        transition: all 0.5s ease;
        font-weight: 700;
        &:hover {
            background: $primary;
            color: $white;
        }
    }
}

.editor-container {
    font-family: inherit;
    color: inherit;
    background-color: inherit;
}

.editor-container h4 {
    @extend .fw-bold;
    letter-spacing: 1px;
    font-size: 32px!important;
    padding: inherit;
    margin: inherit;
    line-height: inherit;
    outline: inherit;
}

.editor-container p {
    @extend .text-muted;   
}

.editor-container .codex-editor__redactor {
    padding-bottom: 0 !important;
}

.cdx-quote__caption, .image-tool__caption, .embed-tool__caption{
    display: none!important;
}

.codex-editor__redactor{
    padding-bottom: 0px!important;
}

.text-muted-custom {
    color: #777 !important;
    font-weight: bold;
}