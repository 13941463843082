//
// helper.scss
//

/*********************************/
/*         Helpers               */
/*===============================*/

.section {
    padding: 100px 0;
    position: relative;
    @media (max-width: 768px) {
        padding: 60px 0;
    }
}

.section-two {
    padding: 60px 0;
    position: relative;
}

.bg-overlay {
    background-color: $overlay;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%
}

.bg-overlay-white {
    background-color: $bg-overlay-white;
}

.bg-linear-gradient {
    background: $linear-gradient;
}

.bg-linear-gradient-2 {
    background: $linear-gradient-2;
}

.bg-linear-gradient-3 {
    background: $linear-gradient-3;
}

.bg-gradient-overlay {
    background: $gradient-overlay;
}

.bg-gradient-overlay-2 {
    background: $gradient-overlay-2;
}

.bg-primary-gradient-overlay {
    background: $primary-gradient-overlay;
}

.bg-gradient-white-overlay {
    background: $gradient-white-overlay;
}


//Title
.title-heading {
    line-height: 26px;
    .heading {
        font-size: 45px !important;
        letter-spacing: 1px;
        &.sub-heading {
            font-size: 32px !important;
            @media (max-width: 768px) {
                font-size: 26px !important;
            }
        }
        @media (max-width: 768px) {
            font-size: 36px !important;
        }
    }
    .para-desc {
        font-size: 17px;
    }
}
.section-title {
    .title {
        font-size: 28px !important;
        
        @media (max-width: 768px) {
            font-size: 24px !important;
        }
    }
}
.para-desc {
    max-width: 600px;
}
.mt-100 {
    margin-top: 100px;
}

// Text Slider
.text-slider {
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
    > ul {
        white-space: nowrap;
    }
    .move-text {
        animation: moveSlideshow 12s linear infinite;
    }
}  
@keyframes moveSlideshow {
    100% { 
        transform: translateX(-45%);  
    }
}

//Mover
.mover {
    animation: mover 1s infinite alternate;
}
@keyframes mover {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(5px);
    }
}

//Skewed
.img-skewed {
    transform: perspective(650px) rotateX(0deg) rotateY(-10deg);
}

//Tobii Lighbox
.tobii__btn svg {
    height: 30px;
    width: auto;
}
.tobii__counter {
    font-size: 16px;
}
.tobii-zoom {
    display: block !important;
}

.tobii-zoom__icon {
    display: none;
}

//CTA
.play-icon {
    position: absolute;
    bottom: 50%;
    transform: translateY(50%);
    right: 0;
    left: 0;
    text-align: center;
    i {
        height: 75px;
        width: 75px;
        font-size: 25px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }
}

.play-btn {
    height: 78px;
    width: 78px;
    font-size: 30px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: $white;
    .icons {
        -webkit-text-stroke: 2px $primary;
        -webkit-text-fill-color: transparent;  
        &:hover {
            -webkit-text-stroke: 2px $primary;
            -webkit-text-fill-color: $primary;
        }
    }
}

#grid {
    padding: 0 !important;
}
  
@media (min-width: 768px) {
    .margin-top-100 {
        margin-top: 100px;
    }
}
@media (max-width: 768px) {
    .mt-60 {
        margin-top: 60px;
    }   
}


.position-middle-bottom {
    position: absolute;
    bottom: 15px;
    left: 12px;
    right: 12px;
    text-align: center;
}

.app-subscribe {
    position: absolute;
    top: -150px;

    @media (max-width: 767px) {
        position: relative;
        top: 0;
    }
}

.features-absolute {
    position: relative;
    z-index: 2;
    transition: all 0.5s ease;
}

.features-absolute {
    margin: -200px 0 0px;

    @media (max-width: 768px) {
        margin: -140px 0 0;
    }
    
    &.blog-search {
        margin: -120px 0 0px;

        @media (max-width: 768px) {
            margin: -90px 0 0px;
        }
    }
}

//Ribbon
.ribbon {
    @each $name,
    $value in $colors {
        &.ribbon-#{$name} {
            span {
                background-color: #{$value} !important;
                &:before {
                    border-left-color: darken($value, 10%);
                    border-top-color: darken($value, 10%);
                }
        
                &:after {
                    border-right-color: darken($value, 10%);
                    border-top-color: darken($value, 10%);
                }
            }
        }
    }
}

.ribbon {
    position: absolute;
    top: -6px;
    width: 75px;
    height: 75px;
    z-index: 2;
    &.ribbon-right {
        right: -4px;
        span {
            right: -23px;
            transform: rotate(45deg);
        }
    }
    &.ribbon-left {
        left: -5px;
        span {
            left: -21px;
            transform: rotate(-45deg);
        }
    }
    span {
        position: absolute;
        top: 18px;
        width: 100px;
        color: $white;
        &:before,
        &:after {
            content: "";
            position: absolute;
            top: 100%;
            z-index: -1;
            border: 3px solid transparent;
        }
        &:before {
            left: 0;
        }

        &:after {
            right: 0;
        }
    }
}

.round-effect {
    .primary-round,
    .gradient-round {
        position: fixed;
        width: 650px;
        height: 650px;
        border-radius: 50%;
        filter: blur(100px);
        pointer-events: none;
        z-index: -1;
    }
    .primary-round {
        top: 5%;
        left: 0;
        background-color: $primary;
    }

    .gradient-round {
        bottom: 5%;
        right: 0;
        background-color: $gradient;
    }
}

//Bg-animation-lines
.background-lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 100%;
    z-index: 0;
    .line {
        background: rgba($primary, 0.1);
        position: absolute;
        width: 1px;
        height: 100%;
        top: 0;
        left: 50%;
        overflow: hidden;
        &:after {
            content: "";
            display: block;
            position: absolute;
            height: 15vh;
            width: 100%;
            top: -50%;
            left: 0;
            background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba($primary, 0.3) 75%, rgba($primary, 0.3) 100%);
            animation: run 7s 0s infinite;
            animation-fill-mode: forwards;
            animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
        }
        &:nth-child(1) {
            margin-left: -25%;
        }
        &:nth-child(3) {
            margin-left: 25%;
        }
        &:nth-child(1):after {
            animation-delay: 2s;
        }
        &:nth-child(3):after {
            animation-delay: 2.5s;
        }
    }
}

@keyframes run {
    0% {
        top: -50%;
    }

    100% {
        top: 100%;
    }
}

//Kenburn Effect
.zoom-image {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    overflow: hidden;
}
.image-wrap {
    position: absolute;
    top: -350px;
    bottom: -350px;
    left: -100px;
    right: -100px;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    overflow: hidden;
    margin: auto;
    animation: 100s ppb_kenburns linear infinite alternate;
}

@keyframes ppb_kenburns{
	0% {
		transform: scale(1.3) translate(-10%, 10%);
	}
	25% {
		transform: scale(1) translate(0, 0);
	}
	50% {
		transform: scale(1.3) translate(10%, 10%);
	}
	75% {
		transform: scale(1) translate(0, 0);
	}
	100% {
		transform: scale(1.3) translate(-10%, 10%);
	}
}


// /*********************************/
// /*         Select2               */
// /*===============================*/
// .css-b62m3t-container{
//     margin-left: 45px;
// }
// .css-13cymwt-control{
//     height: 60px !important;
//     border-color: transparent !important;
//     border-style: none !important;
//     --tw-bg-opacity: 1;
//     background-color: rgb(249 250 251 / var(--tw-bg-opacity)) !important;
//   }
//   :is(.dark .css-13cymwt-control){
//     --tw-bg-opacity: 1;
//     background-color: rgb(30 41 59 / var(--tw-bg-opacity)) !important;
//   }
  
//   .css-t3ipsp-control {
//     height: 60px !important;
//     border-color: transparent !important;
//     --tw-bg-opacity: 1;
//     background-color: rgb(249 250 251 / var(--tw-bg-opacity)) !important;
//   }
  
//   :is(.dark .css-t3ipsp-control){
//     --tw-bg-opacity: 1;
//     background-color: rgb(30 41 59 / var(--tw-bg-opacity)) !important;
//   }
  
//   .css-1u9des2-indicatorSeparator{
//     display: none !important;
//   }
  
//   .css-1fdsijx-ValueContainer{
//     padding: 0px !important;
//   }
  
//   .css-qbdosj-Input {
//     margin: 0 !important;
//     padding: 0px !important;
//   }
  
//   .css-13cymwt-control:hover,
//   .css-13cymwt-control:focus,
//   .css-t3ipsp-control:hover,
//   .css-t3ipsp-control:focus{
//     border-color: transparent !important;
//     border-style: none !important;
//     --tw-shadow: 0 0 #0000 !important;
//     --tw-shadow-colored: 0 0 #0000 !important;
//     -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
//             box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
//   }
  
  
//   .css-1dimb5e-singleValue{
//     --tw-text-opacity: 1;
//     color: rgb(22 28 45 / var(--tw-text-opacity)) !important;
//   }
  
//   :is(.dark .css-1dimb5e-singleValue){
//     --tw-text-opacity: 1;
//     color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
//   }
  
//   .css-1nmdiq5-menu{
//     z-index: 999;
//     border-radius: 5px;
//     border-style: none !important;
//     --tw-bg-opacity: 1;
//     background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
//     --tw-shadow: 0 0 3px rgb(60 72 88 / 0.15);
//     --tw-shadow-colored: 0 0 3px var(--tw-shadow-color);
//     -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
//             box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
//     --tw-text-opacity: 1;
//     color: rgb(22 28 45 / var(--tw-text-opacity)) !important;
//   }
  
//   :is(.dark .css-1nmdiq5-menu){
//     --tw-bg-opacity: 1;
//     background-color: rgb(15 23 42 / var(--tw-bg-opacity)) !important;
//     --tw-shadow-color: #1f2937;
//     --tw-shadow: var(--tw-shadow-colored);
//     --tw-text-opacity: 1;
//     color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
//   }
  
//   .css-13cymwt-control:after,
//   .css-13cymwt-control:before{
//     --tw-shadow: 0 0 #0000;
//     --tw-shadow-colored: 0 0 #0000;
//     -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
//             box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
//   }
  .text-3xl {
    font-size: 1.875rem; /* 30px */
    line-height: 2.25rem; /* 36px */
  }
  .p-2{
    padding: 0.5rem; /* 8px */
  }

  .carousel .slider.animated {
    transition: all .35s ease-in-out;
    height: 100vh !important;
}