//
// general.scss
//

/*********************************/
/*         General               */
/*===============================*/

body {
    font-family: $font-family-secondary;
    overflow-x: hidden !important;
    font-size: $font-size-base;
    color: $black;
    background: $bg-body;
    letter-spacing: 0.25px;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: $font-family-base;
    line-height: 1.5;
    font-weight: 600;
}

.display-1,
.display-2,
.display-3,
.display-4,
.display-5,
.display-6 {
    line-height: 1.3;
}
::selection {
    background: rgba($primary, 0.9);
    color: $white;
}
a {
    text-decoration: none !important;
    transition: all 0.5s ease;
    color: #777;
}
a:hover, a:active, a:focus{
  color: $primary !important;
}
p {
    line-height: 1.6;
}
ul li{
    -webkit-margin-start: 0.25rem;
            margin-inline-start: 0.25rem;
  }
  ul li:first-child{
    -webkit-margin-start: 0px;
            margin-inline-start: 0px;
  }

  @keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  
  .pulse {
    display: inline-block;
    width: 100%;
    height: 100%;
    background-color: #f0f0f0;
    animation: pulse 1.5s infinite ease-in-out;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  button.xmint-btn {
    width: auto!important;
    height: auto!important;
    padding: 5.75px 20px!important;
    position: relative;
  }
  button.xmint-btn::before {
      content: "\F0110";
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      display: inline-block;
      font: normal normal normal 24px / 1 Material Design Icons;
      font-size: inherit;
      line-height: inherit;
      text-rendering: auto;
      margin-right: 10px;
  }
  button.xmint-btn img{
    display: none!important;
  }
  .list-group-item:hover, .list-group-item:active, .list-group-item:focus, .list-group-item.active {
    color: #ffffff!important;
    background-color: #5a6d90 !important;
    transition: background-color 0.3s ease;
  }
  .list-group-item:hover span, .list-group-item:active span, .list-group-item:focus span, .list-group-item.active span {
    color: #ffffff!important;
  }
  .uil-tiktok::before {
    content: " ";
    display: inline-block;
    width: 15.25px;
    height: 12px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("data:image/svg+xml;utf8,<svg fill='%23495057' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' stroke='%23495057'><path d='M19.589 6.686a4.793 4.793 0 0 1-3.77-4.245V2h-3.445v13.672a2.896 2.896 0 0 1-5.201 1.743l-.002-.001.002.001a2.895 2.895 0 0 1 3.183-4.51v-3.5a6.329 6.329 0 0 0-5.394 10.692 6.33 6.33 0 0 0 10.857-4.424V8.687a8.182 8.182 0 0 0 4.773 1.526V6.79a4.831 4.831 0 0 1-1.003-.104z'/></svg>");
  }
  .social-icon li a:hover .uil-tiktok::before {
    background-image: url("data:image/svg+xml;utf8,<svg fill='%23ffffff' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' stroke='%23ffffff'><path d='M19.589 6.686a4.793 4.793 0 0 1-3.77-4.245V2h-3.445v13.672a2.896 2.896 0 0 1-5.201 1.743l-.002-.001.002.001a2.895 2.895 0 0 1 3.183-4.51v-3.5a6.329 6.329 0 0 0-5.394 10.692 6.33 6.33 0 0 0 10.857-4.424V8.687a8.182 8.182 0 0 0 4.773 1.526V6.79a4.831 4.831 0 0 1-1.003-.104z'/></svg>");
  }
  .lazy-load-image-loaded{
    display: flex!important;
  }
  .text-shape{
    color: $shape;
  }

  
@media (max-width: 991px) {
  .tiny-slider{
      margin-top:80px;
  }
}

.react-tooltip {
  z-index: 1050; /* Higher than other elements */
}
@media (max-width: 767.98px) {
  .react-tooltip {
      width: min-content!important; /* Full width on mobile */
  }
}

@media (min-width: 768px) {
  .react-tooltip {
      width: max-content!important; /* Original width on desktop */
  }
}

/* Webkit (Chrome, Safari) */
::-webkit-scrollbar-track {
  background-color: #333;
}
::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 6px;
}
/* Firefox */
* {
scrollbar-color: gray #333;
}