//
// home.scss
//

/*********************************/
/*         Home & Hero           */
/*===============================*/

@mixin home-common {
    background-size: cover;
    align-self: center;
    position: relative;
    background-position: center center;
}
.bg-home {
    height: 100vh;    
    @include home-common();
}
.bg-home-75vh {
    height: 75vh !important;    
    @include home-common();
    @media (max-width: 767px) {
        height: 80vh !important;
    }
}
.bg-half-260 {
    padding: 260px 0;    
    @include home-common();
}
.bg-half-170 {
    padding: 170px 0;    
    @include home-common();
}
.bg-half-100 {
    padding: 100px 0;    
    @include home-common();
}
.bg-half {
    padding: 184px 0 90px;
    @include home-common();
}

.bg-creator-profile,
.bg-item-detail,
.bg-half-174 {
    padding: 174px 0 100px;    
    @include home-common();
}

.bg-auth-home {
    padding: 142px 0;
    @include home-common();
}

//pages
.bg-half-page {
    padding: 150px 0 100px;
    position: relative;
    @media (max-width: 767px) {
        padding: 100px 0 60px;
    }
}

//Saas
.home-dashboard {
    img {
        position: relative;
        top: 60px;
        z-index: 1;
    }
    
    //Index-onepage.html
    @media (min-width:768px) {
        &.onepage-hero {
            overflow: hidden;
        }
    }
}

//NFT Marketplace
.nft-margin {
    margin-top: 74px;
}

//Shapes
.shape {
    position: absolute;
    pointer-events: none;
    right: 0;
    bottom: -2px;
    left: 0;
    &>svg {
        transform: scale(2);
        width: 100%;
        height: auto;
        transform-origin: top center;
    }
    
    @media (max-width: 425px) {
        bottom: -4px;
    }
}

@media (max-width: 768px) {
    .bg-half {
        padding: 169px 0 50px;
    }
}

@media (max-width: 767px) {
    .bg-home, .bg-half-170, .bg-half-260, .bg-marketing, .swiper-slider-hero .swiper-container .swiper-slide {        
        padding: 150px 0;
        height: auto;
    } 
}

//Bg Video Hero
.bg-video-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    pointer-events: none;
    overflow: hidden;
    iframe {
        width: 100vw;
        height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
        min-height: 100vh;
        min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
     }
}

.loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 400px;
}
.coin-spin {
    display: inline-block;
    animation: coin-spin 1.5s infinite linear;
    max-height: 100px;
}
  
@keyframes coin-spin {
0% {
    transform: rotateY(0deg);
}
50% {
    transform: rotateY(180deg);
}
100% {
    transform: rotateY(360deg);
}
}